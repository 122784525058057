<template>
  <div id="scene-container" ref="sceneContainer">
    <div id="ui-container" ref="uiContainer">
      <Nav />
      <Welcome v-if="isVisible" v-on:triggered="startedScene"/>
    </div>
  </div>
</template>

<script>
import * as THREE from "three";
import {
  Mesh,
  BoxGeometry, MeshBasicMaterial,
  WebGLRenderer,
  sRGBEncoding, Clock,

  LineBasicMaterial, LineSegments,
  AdditiveBlending, BufferAttribute,
  PointsMaterial, BufferGeometry, Vector3,
  Points, DynamicDrawUsage, Group,   Color,


} from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'
import { Water } from 'three/examples/jsm/objects/Water.js';
//import { Sky } from 'three/examples/jsm/objects/Sky.js';


import Nav from "../components/Nav"
import Welcome from "../components/Welcome"
export default {
  name: "Landing",
  components: {
    Nav,
    Welcome
  },
  data() {
    return {
      cubeModel: null,
      isVisible: true
    }
  },
  methods: {
    async init() {
      // set container
      this.container = this.$refs.sceneContainer

      // add camera
      this.camera = new THREE.PerspectiveCamera(40, window.innerWidth / window.innerHeight, 1, 1000);
      this.camera.position.set(50, 50, 50);
      //this.camera.lookAt(new THREE.Vector3(0, 0, 0));

      // create renderer
      this.renderer = this.createRenderer()
      this.container.appendChild(this.renderer.domElement)

      // create scene
      this.scene = new THREE.Scene()
     // this.scene.background = new THREE.Color('#EEEEEE')
      this.scene.background = new Color(0x000033)

      //this.scene.fog = new THREE.FogExp2(0xEEEEEE, 0.001);


      this.clock = new Clock()

      this.controls = new OrbitControls(this.camera, this.container)
      this.controls.enableDamping = true; // an animation loop is required when either damping or auto-rotation are enabled
      this.controls.dampingFactor = 0.025;
      this.controls.screenSpacePanning = false;
      this.controls.enableZoom = false;
      this.controls.minPolarAngle = Math.PI / 15;
      this.controls.maxPolarAngle = Math.PI / 2.25;

      // add lights
      //const hemiLight = new THREE.HemisphereLight(0xffffff, 0x080808, 1.5);
      //hemiLight.position.set(-1.25, 1, 1.25);

      //const mainLight = new THREE.DirectionalLight(0xffffff, 4.0)
      //mainLight.position.set(-10, 10, 10)
      //this.scene.add(hemiLight, mainLight)

      // cube geometry
      this.cubeModel = this.createCube(2,2,2)
      this.cubeModel.position.z = 0
      this.scene.add(this.cubeModel);

      // Sun

      this.sun = new THREE.Vector3();

      // Water
      const waterGeometry = new THREE.PlaneGeometry( 10000, 10000 );

      this.water = new Water(
          waterGeometry,
          {
            textureWidth: 512,
            textureHeight: 512,
            waterNormals: new THREE.TextureLoader().load( 'textures/waternormals.jpg', function ( texture ) {

              texture.wrapS = texture.wrapT = THREE.RepeatWrapping;

            } ),
            sunDirection: new THREE.Vector3(),
            sunColor: 0xb8b8b8,
            waterColor: 0x000026,
            distortionScale: 3.7,
            fog: this.scene.fog !== undefined
          }
      );

      this.water.rotation.x = - Math.PI / 2;

      this.scene.add( this.water );


      // Skybox
     /* const sky = new Sky();
      sky.scale.setScalar( 10000 );
      this.scene.add( sky );

      const skyUniforms = sky.material.uniforms;

      skyUniforms[ 'turbidity' ].value = 15;
      skyUniforms[ 'rayleigh' ].value = 3;
      skyUniforms[ 'mieCoefficient' ].value = 0.1;
      skyUniforms[ 'mieDirectionalG' ].value = 0;

      const parameters = {
        elevation: 0,
        azimuth: 180
      };

      const pmremGenerator = new THREE.PMREMGenerator( this.renderer );

      const phi = THREE.MathUtils.degToRad( 90 - parameters.elevation );
      const theta = THREE.MathUtils.degToRad( parameters.azimuth );

      this.sun.setFromSphericalCoords( 1, phi, theta );

      sky.material.uniforms[ 'sunPosition' ].value.copy( this.sun );
      this.water.material.uniforms[ 'sunDirection' ].value.copy( this.sun ).normalize();

      this.scene.environment = pmremGenerator.fromScene( sky ).texture;
*/
      this.createParticles()



      if (this.isTriggered == 'true'){
        console.log('clicked')
        this.isVisible = false

      }

    },

    render() {
      this.renderer.render(this.scene, this.camera)
      // this.stats.update()
      //rotate model

    },

    animate() {
      this.renderer.setAnimationLoop(() => {
        this.render()
        let delta = this.clock.getDelta()
        this.controls.update(delta)

        this.cubeModel.rotation.y += 0.01
        this.water.material.uniforms['time'].value += 1.0 / 120.0;

        // particle section
        var vertexpos = 0
        var colorpos = 0
        var numConnected = 0

        for (var b = 0; b < this.particleCount; b++) { this.particlesData[ b ].numConnections = 0 }

        for (var g = 0; g < this.particleCount; g++) {
          // get the particle
          var particleData = this.particlesData[ g ]

          this.particlePositions[ g * 3 ] += particleData.velocity.x
          this.particlePositions[ g * 3 + 1 ] += particleData.velocity.y
          this.particlePositions[ g * 3 + 2 ] += particleData.velocity.z

          if (this.particlePositions[ g * 3 + 1 ] < -this.rHalf || this.particlePositions[ g * 3 + 1 ] > this.rHalf) { particleData.velocity.y = -particleData.velocity.y }

          if (this.particlePositions[ g * 3 ] < -this.rHalf || this.particlePositions[ g * 3 ] > this.rHalf) { particleData.velocity.x = -particleData.velocity.x }

          if (this.particlePositions[ g * 3 + 2 ] < -this.rHalf || this.particlePositions[ g * 3 + 2 ] > this.rHalf) { particleData.velocity.z = -particleData.velocity.z }

          if (this.effectController.limitConnections && particleData.numConnections >= this.effectController.maxConnections) { continue }

          // Check collision
          for (var j = g + 1; j < this.particleCount; j++) {
            var particleDataB = this.particlesData[ j ]
            if (this.effectController.limitConnections && particleDataB.numConnections >= this.effectController.maxConnections) { continue }

            var dx = this.particlePositions[ g * 3 ] - this.particlePositions[ j * 3 ]
            var dy = this.particlePositions[ g * 3 + 1 ] - this.particlePositions[ j * 3 + 1 ]
            var dz = this.particlePositions[ g * 3 + 2 ] - this.particlePositions[ j * 3 + 2 ]
            var dist = Math.sqrt(dx * dx + dy * dy + dz * dz)

            if (dist < this.effectController.minDistance) {
              particleData.numConnections++
              particleDataB.numConnections++

              var alpha = 1.0 - dist / this.effectController.minDistance

              this.positions[ vertexpos++ ] = this.particlePositions[ g * 3 ]
              this.positions[ vertexpos++ ] = this.particlePositions[ g * 3 + 1 ]
              this.positions[ vertexpos++ ] = this.particlePositions[ g * 3 + 2 ]

              this.positions[ vertexpos++ ] = this.particlePositions[ j * 3 ]
              this.positions[ vertexpos++ ] = this.particlePositions[ j * 3 + 1 ]
              this.positions[ vertexpos++ ] = this.particlePositions[ j * 3 + 2 ]

              this.colors[ colorpos++ ] = alpha
              this.colors[ colorpos++ ] = alpha
              this.colors[ colorpos++ ] = alpha

              this.colors[ colorpos++ ] = alpha
              this.colors[ colorpos++ ] = alpha
              this.colors[ colorpos++ ] = alpha

              numConnected++
            }
          }
        }

        this.linesMesh.geometry.setDrawRange(0, numConnected * 2)
        this.linesMesh.geometry.attributes.position.needsUpdate = true
        this.linesMesh.geometry.attributes.color.needsUpdate = true

        this.pointCloud.geometry.attributes.position.needsUpdate = true



      })
      this.establishListeners()
    },

    createRenderer() {
      const renderer = new WebGLRenderer({antialias: true})
      renderer.setSize(window.innerWidth, window.innerHeight)
      renderer.setPixelRatio(window.devicePixelRatio)
      renderer.gammaFactor = 2.2
      renderer.outputEncoding = sRGBEncoding
      renderer.physicallyCorrectLights = true
      return renderer
    },

    startedScene(){
      console.log('triggered')
      this.isVisible = false
      console.log(this.$store.getters.enteredWeb)
    },


    establishListeners() {
      window.addEventListener('resize', this.onWindowResize.bind(this), false)

    },

    onWindowResize() {
      // set aspect ratio to match the new browser window aspect ratio
      this.camera.aspect = window.innerWidth / window.innerHeight;
      this.camera.updateProjectionMatrix();
      this.renderer.setSize(window.innerWidth, window.innerHeight);

    },

    createCube(width, height, depth) {
      const geometry = new BoxGeometry(width, height, depth)

      const material = new MeshBasicMaterial({color: 0x76d32e});
      return new Mesh(geometry, material)
    },

    createParticles () {
      this.group  = new Group()
      this.scene.add(this.group)
      this.particlePositions = null
      this.particlesData = []
      this.maxParticleCount = 1000
      this.particleCount = 400
      this.r = 800
      this.rHalf = this.r / 2

      this.effectController = {
        showDots: false,
        showLines: true,
        minDistance: 100,
        limitConnections: false,
        maxConnections: 25,
        particleCount: 150
      }
      var segments = this.maxParticleCount * this.maxParticleCount

      this.positions = new Float32Array(segments * 3)
      this.colors = new Float32Array(segments * 3)

      var pMaterial = new PointsMaterial({
        color: 0xFFFFFF,
        size: 3,
        blending: AdditiveBlending,
        transparent: true,
        sizeAttenuation: false
      })

      this.particles = new BufferGeometry()
      this.particlePositions = new Float32Array(this.maxParticleCount * 3)

      for (var i = 0; i < this.maxParticleCount; i++) {
        var x = Math.random() * this.r - this.r / 2
        var y = Math.random() * this.r - this.r / 2
        var z = Math.random() * this.r - this.r / 2

        this.particlePositions[ i * 3 ] = x
        this.particlePositions[ i * 3 + 1 ] = y
        this.particlePositions[ i * 3 + 2 ] = z

        // add it to the geometry
        this.particlesData.push({
          velocity: new Vector3(-1 + Math.random() * 2, -1 + Math.random() * 2, -1 + Math.random() * 2),
          numConnections: 0
        })
      }

      this.particles.setDrawRange(0, this.particleCount)
      this.particles.setAttribute('position', new BufferAttribute(this.particlePositions, 3).setUsage(DynamicDrawUsage))

      // create the particle system
      this.pointCloud = new Points(this.particles, pMaterial)
      this.group.add(this.pointCloud)

      var geometry = new BufferGeometry()

      geometry.setAttribute('position', new BufferAttribute(this.positions, 3).setUsage(DynamicDrawUsage))
      geometry.setAttribute('color', new BufferAttribute(this.colors, 3).setUsage(DynamicDrawUsage))

      geometry.computeBoundingSphere()

      geometry.setDrawRange(0, 0)

      var material = new LineBasicMaterial({
        vertexColors: true,
        blending: AdditiveBlending,
        transparent: true
      })

      this.linesMesh = new LineSegments(geometry, material)
      this.group.add(this.linesMesh)
      this.group.position.y = 400
    }

  },

  mounted() {
    this.init().then(() => this.animate())
  },

  computed: {
    isTriggered() {
      console.log(this.$store.getters.enteredWeb)
      return this.$store.getters.enteredWeb;

    },
  }
}
</script>

<style scoped>
#scene-container {
  height: 100%;
  width: 100%;
  overflow: hidden;
  z-index: 96;
}

#ui-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
}
</style>
